<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:body>
            <form action="#" v-if="vmGPathSendMailFormData && Object.keys(vmGPathSendMailFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationsession_user_name">{{cvSendMailUserNameLabel}}</label>
                  <input v-model="vmGPathSendMailFormData.user_name" type="text" class="form-control" required/>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="validationsession_user_email">{{cvSendMailUserEmailLabel}}</label>
                  <input v-model="vmGPathSendMailFormData.user_email" type="text" class="form-control" required/>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="validationsession_parent_email">{{cvSendMailParentEmailLabel}}</label>
                  <input v-model="vmGPathSendMailFormData.cc_email" type="text" class="form-control" required/>
                </div>

                <!-- Is Email Custom -->
                <div class="col-md-12 mb-3 gide_inline_textorcheckbox">
                  <input id="is_email_custom" v-model="vmGPathSendMailFormData.is_email_custom" type="checkbox" class="form-control" required />&nbsp;
                  <label class="lable" for="is_email_custom">{{ cvCustomMailCheckbox }}</label>
                </div><!-- Is Email Custom -->

                <div class="col-md-12 mb-3">
                  <label for="validationsession_state">{{cvSendMailEmailModule}}</label>
                  <select v-model="vmGPathSendMailFormData.subject" name="" id="" class="form-control">{{JSON.stringify(vmGPathSendMailFormData.subject)}}
                    <option :value="subject" v-for="(subject, index) in propSendMailObj.subject" :key="index">{{subject}}</option>
                  </select>
                </div>

                <div class="col-md-12 mb-3" v-if="vmGPathSendMailFormData.is_email_custom">
                  <label for="validationsession_subject">{{"Subject"}}</label>
                  <input v-model="vmGPathSendMailFormData.custom_subject" type="text" class="form-control" required/>
                </div>

                <div class="col-md-12 mb-3" v-if="vmGPathSendMailFormData.is_email_custom">
                  <label for="validationsession_message">{{"Message"}}</label>
                  <textarea
                    v-model="vmGPathSendMailFormData.custom_message"
                    type="text"
                    class="form-control textarea"
                    maxlength="150"
                    style="height: 250px;"
                    required
                  />
                </div>

                <!-- Spcm Session List -->
                <div class="col-md-12 mb-3" v-if="sessionObjList && sessionObjList.length > 0">
                  <label for="validationsession_state">Choose the session from below</label>
                  <select class="form-control mb-2" v-model="vmGPathSendMailFormData.session_id">
                    <option v-for="(sessionObj) of sessionObjList" :key="sessionObj.session_id" :value="sessionObj.session_id">
                      {{ sessionObj.session_title}}
                    </option>
                  </select>
                </div><!-- Spcm Session List -->
              </div>

              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="gpath_send_mail()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import ApiResponse from "../Utils/apiResponse"
import { SendMail } from "../FackApi/api/sendMail.js"
import GideProducts from "../FackApi/json/GideProducts.json"
import { SpcmSessions } from "../FackApi/api/spcmSession.js"

export default {
  name: "SendMail",
  props: {
    propSendMailObj: {
      type: Object,
      default: function () {
        return {
          "user_name": "",
          "user_email": "",
          "cc_email": "",
          "subject": {}
        }
      }
    },
    propModuleName: {
      type: String,
      default: "gpaths"
    }
  },
  data () {
    return {
      GideProducts: GideProducts,
      cvSubmitBtn: "Send Mail",
      cvSendMailUserNameLabel: "User",
      cvSendMailUserEmailLabel: "Email",
      cvSendMailParentEmailLabel: "CC Email",
      cvSendMailEmailModule: "Choose Subject",
      cvCustomMailCheckbox: "Add Additional Notes",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Send Mail Response",
      vmGPathSendMailFormData: this.initFormData(),
      sessionObjList: [],
      module_name: null,
      module_obj_id: null
    }
  },
  mounted () {
    this.module_name = this.propModuleName
    this.module_obj_id = this.propSendMailObj.module_obj_id ? this.propSendMailObj.module_obj_id : null
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmGPathSendMailFormData) {
          if (!this.vmGPathSendMailFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * initFormData
     */
    initFormData () {
      return {
        user_id: this.propSendMailObj.user_id,
        user_name: this.propSendMailObj.user_name,
        user_email: this.propSendMailObj.user_email,
        cc_email: this.propSendMailObj.cc_email,
        subject: ""
      }
    },
    /**
     * gpath_send_mail
     */
    async gpath_send_mail () {
      try {
        if (this.propSendMailObj.counsellor_id) {
          this.vmGPathSendMailFormData.counsellor_id = this.propSendMailObj.counsellor_id
        }

        if (this.propSendMailObj.module_name) {
          this.vmGPathSendMailFormData.module_name = this.propSendMailObj.module_name
        }

        if (this.propSendMailObj.module_obj_id) {
          this.vmGPathSendMailFormData.module_obj_id = this.propSendMailObj.module_obj_id
        }

        if (this.vmGPathSendMailFormData.session_id) {
          this.vmGPathSendMailFormData.session_title = this.sessionObjList.find(e => e.session_id == this.vmGPathSendMailFormData.session_id)?.session_title
        }

        const sendMailResp = await SendMail.sendMailToUser(this, this.vmGPathSendMailFormData)
        if (sendMailResp.resp_status) {
          this.$emit("emitCloseGPathSendMailModal")
        }
        ApiResponse.responseMessageDisplay(this, sendMailResp)
      }
      catch (err) {
        console.error("Exception occurred at gpath_send_mail() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * sessionList
     */
    async sessionList () {
      try {
        const spcmSessionList = await SpcmSessions.spcmSessionList(this, { spcm_id: this.propSendMailObj.spcm_id })
        if (!spcmSessionList.resp_status) {
          this.toastMsg = "No sessions found"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
        this.sessionObjList = spcmSessionList.resp_data.data
      }
      catch (err) {
        console.error("Exception in sessionList() and err: ", err)
      }
    }
  },
  watch: {
    async "vmGPathSendMailFormData.subject" (newVal) {
      if (newVal == this.propSendMailObj.subject.SPCM_SPECIFIC_SESSION_NOTE) {
        this.sessionList()
      }
    }
  }
}
</script>
<style scoped>
</style>
