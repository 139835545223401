<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="allCardList p-0">
        <iq-card style="padding: 10px;">
          <template>
            <h4 class="card-title">
              <span>{{cvCardTitle}}</span>
              <span class="pull-right btn_in_header" v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'">
                <b-button class="mr-2" variant="primary" @click="goBack()" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">Go Back</b-button>
              </span>
            </h4>
            <span><b>Counselor Name:</b> {{userData.user_name}} </span>
            <div class="m-2">
              <b-badge v-for="(gac,index) in gpath_affiliate_count" :key="index" class="tags mr-2 mb-2 font-weight-normal" variant="none" :style="setTagStyles(index)">
                <span>{{index.replace("_"," ")}}</span>:&nbsp;<span>{{gac}}</span>
              </b-badge>
            </div>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:body>
            <b-row class="row w-100">
              <!-- Search Input Box -->
              <b-col sm="2" md="2" lg="2" xl="2" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Search Input Box -->

              <!-- Counselor Name Filter -->
              <b-col sm="2" md="2" lg="2" xl="2" class="mb-2" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11116'">
                <select v-model="whereFilter.counsellor_id" class="form-control" @change="setCounsellor" v-if="counsellorObjList.length > 0" title="Counselor List">
                  <option :value="null" selected >Counselor List</option>
                  <option v-for="(counsellor, index) of counsellorObjList" :key="(index+1)" :value="counsellor.user_id">
                    {{counsellor.user_name}}
                  </option>
                </select>
              </b-col><!-- Counselor Name Filter -->

              <!-- Used/Unused Aff_code Filter -->
              <b-col sm="2" md="2" lg="2" xl="2" class="mb-2" title="Used Codes">
                <select v-model="whereFilter.aff_code_status" class="form-control" @change="setAffCodeStatus">
                  <!-- 0=NOT ALLOCATED, 1=ALLOCATED 2=USED-->
                  <option :value="2">Used Codes</option>
                  <option :value="1">Allocated but Not Used</option>
                  <option :value="0">Un-Allocated Codes</option>
                </select>
              </b-col><!-- Used/Unused Aff_code Filter -->

              <!-- Module Name Filter -->
              <!-- @subho: todo:- when we launch Gideprep in saas mode, we need to activate the below filter for orgsaas admins -->
              <b-col sm="2" md="2" lg="2" xl="2" class="mb-2" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">
                <select v-model="whereFilter.module_name" class="form-control" @change="setModuleName" v-if="moduleObjList.length > 0" title="Module List">
                  <option>Modules Associated With Codes</option>
                  <option v-for="(module, index) of moduleObjList" :key="(index+1)" :value="module.mod_name">
                    {{module.mod_name}}
                  </option>
                </select>
              </b-col><!-- Module Name Filter -->

              <!-- Date Range Picker -->
              <b-col sm="2" md="2" lg="2" xl="2" class="pr-0 mb-3 float-right">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="getAffCodesDateWise()" ></date-range-picker>
                </div>
              </b-col><!-- Date Range Picker -->

              <!-- Download CSV button -->
              <b-col sm="2" md="2" lg="2" xl="2" class="pr-0 mb-3 float-right">
                <VueJsonToCsv :json-data="gpath_affiliate_codeObjList" class="pull-right" :csv-title="csvTitle" v-if="gpath_affiliate_codeObjList && gpath_affiliate_codeObjList.length > 0">
                  <b-btn variant="primary m-2">CSV</b-btn>
                </VueJsonToCsv>
              </b-col><!-- Download CSV button -->

              <!-- Buy More codes button -->
              <b-col sm="2" md="2" lg="2" xl="2" class="pr-0 mb-3 float-right">
                <b-btn v-if="userData.user_role == 'USERROLE11116'" variant="primary m-2" @click="purchaseAffCodes">Buy More Codes</b-btn>
              </b-col><!-- Buy More codes button -->
            </b-row>
            <!-- Payment Gateway -->
            <b-modal size="xl" v-model="openPayw">
              <Paygw :propModuleType="modName" :propModuleName="modName" :propRedirect="'/affiliate_codes_dashboard'" :propModuleObjId="codesModuleObjId" @emitPaymentStatus="emitPaymentStatus" :propSrc="modName" :propUserRole="'USERROLE11116'"/>
            </b-modal><!-- Payment Gateway -->
            <!-- SendMail -->
            <b-modal
              v-model="showModelSendMail"
              scrollable
              :title="cvSendMailModalHeader"
            >
              <SendMail :propOpenedInModal="true" :propSendMailObj="sendMailObj" @emitCloseGPathSendMailModal="emitCloseGPathSendMailModal" />
              <template #modal-footer="">
                <b-button size="sm" @click="emitCloseGPathSendMailModal()">
                  {{cvbtnModalCancel}}
                </b-button>
              </template>
            </b-modal><!-- SendMail -->
            <b-row>
              <b-col class="container_minheight" md="12" v-if="gpath_affiliate_codeObjList && gpath_affiliate_codeObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="gpath_affiliate_codeObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                <!-- user Name -->
                <template v-slot:cell(user_name)="data">
                  <span>{{data.item.user_name}}</span><br>
                  <small>
                    Id: {{data.item.user_id}}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.user_id)"></i><br/>
                    Email: {{data.item.user_email}}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Email" @click="doCopy(data.item.user_email)"></i><br/>
                  </small>
                </template><!-- User Name -->

                <!-- Module Info -->
                <template v-slot:cell(module_object_id)="data">
                  <small>
                    <span>Name:&nbsp;{{data.item.module_name ? data.item.module_name : "-"}}</span><br>
                    <b-badge :style="getStateColorCode(data.item.aff_code_status)">{{data.item.aff_code_status}}</b-badge>
                  </small>
                </template><!-- Module Info -->

                <!-- Affiliate Code -->
                <template v-slot:cell(aff_code)="data">
                  <span style="color:#e5252c;">{{data.item.aff_code}}</span><br>
                  <span><b-button v-if="whereFilter.aff_code_status != '2'" class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" size="sm" @click="gpath_sendMail('MODAL', data.item)" title="Send Mail"><i class="fa fa-envelope-o"></i></b-button></span>
                </template>
                <!-- Affiliate Code -->

                <!-- Last Modified Date -->
                <template v-slot:cell(last_modified_date)="data">
                  {{ data.item.last_modified_date | dateFormat }}
                </template><!-- Last Modified Date -->

                <!-- Counselor Name -->
                <template v-slot:cell(counsellor_name)="data">
                  <p style="margin: 0px;">{{data.item.counsellor_name}}</p>
                  <small>
                    id: {{data.item.counsellor_id}} <br/>
                  </small>
                </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { socialvue } from "../../../config/pluginInit"
import { Affiliates } from "../../../FackApi/api/Affiliate"
import SendMailModules from "../../../FackApi/json/SendMailModules.json"
import SendMail from "../../../components/SendMail.vue"
import { User } from "../../../FackApi/api/user"
import { modules } from "../../../FackApi/api/modules"
import userPermission from "../../../Utils/user_permission"
import Paygw from "../Paygw/Paygw.vue"
import { Organisations } from "../../../FackApi/api/organisation"
import { OrgSaas } from "../../../FackApi/api/orgSaas.js"
import ApiResponse from "../../../Utils/apiResponse"
import GideProducts from "../../../FackApi/json/GideProducts.json"

export default {
  name: "GPathSessionAffiliateList",
  components: {
    SendMail,
    Paygw
  },
  data () {
    return {
      apiName: "affiliate_list",
      cvCardTitle: "Discount Codes Dashboard",
      cvbtnMangeAffCodes: "GPath Discount Codes",
      cvSearchText: "Type to Search",
      showModelSendMail: false,
      cvSendMailModalHeader: "GPaths Send Mail",
      cvbtnModalCancel: "Cancel",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GPaths Affiliate Console",
      csvTitle: "GPath Affiliate codes",
      sortBy: "name",
      sortDesc: false,
      columns: [],
      gpath_affiliate_codeObjList: null,
      counsellorObjList: [],
      moduleObjList: [],
      gpath_affiliate_count: [],
      filter: null,
      filterOn: ["user_name", "aff_code", "user_id", "user_email"],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        },
        counsellor_id: null,
        aff_code_status: 1 // ALLOCATED
      },
      GideProducts: GideProducts,
      sendMailObj: null,
      SendMailModules: SendMailModules,
      openPayw: false,
      modName: GideProducts.GPATHS,
      codesModuleObjId: "SAASORGGPATH",
      orgId: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    },
    saasOrgData () {
      return this.$store.getters["SaasOrgData/getSaasOrgData"]
    }
  },
  async beforeMount () {
  // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()

    if (!this.saasOrgData) {
      // org_admin visits GIDE from the platform's login page
      const otherOrgId = Object.keys(this.userData.modules.organisations).find(e => e != "GIDE_ORG_1")

      if (otherOrgId) {
        this.orgId = otherOrgId
        await this.getOrgDetails()
      }
    }
    else {
      this.orgData = this.saasOrgData
    }

    await this.getAffiliateCount()
    await this.setFilters()
    switch (this.userData.user_role) {
      case "USERROLE11116":
        await this.getOrgSaasAffiliateList()
        break
      default:
        await this.getAffiliateList()
    }

    switch (this.userData.user_role) {
      case "USERROLE11118":
      case "USERROLE11116":
        this.columns = [
          { label: "#", key: "id", class: "text-left align-text-top", sortable: true },
          { label: "User Name", key: "user_name", class: "text-left align-text-top w-300px", sortable: true },
          { label: "Module Info", key: "module_object_id", class: "text-left align-text-top w-300px", sortable: true },
          { label: "Affiliate Code", key: "aff_code", class: "text-left align-text-top w-300px", sortable: true },
          { label: "Last Used Date", key: "last_modified_date", class: "text-left align-text-top w-300px", sortable: true }
        ]

        // Add the Team Member column only if the user is an organisation admin
        if (this.userIsOrgAdmin()) {
          this.columns.push({ label: "Team Member", key: "counsellor_name", class: "text-left align-text-top w-300px", sortable: true })
        }
        break
      case "USERROLE11111":
        this.columns = [
          { label: "#", key: "id", class: "text-left align-text-top", sortable: true },
          { label: "User Name", key: "user_name", class: "text-left align-text-top w-300px", sortable: true },
          { label: "Module Info", key: "module_object_id", class: "text-left align-text-top w-300px", sortable: true },
          { label: "Affiliate Code", key: "aff_code", class: "text-left align-text-top w-300px", sortable: true },
          { label: "Counsellor", key: "counsellor_name", class: "text-left align-text-top w-300px", sortable: true },
          { label: "Last Used Date", key: "last_modified_date", class: "text-left align-text-top w-300px", sortable: true }
        ]
        break
    }
  },
  methods: {
    /**
     * getAffiliateList
     */
    async getAffiliateList () {
      const payload = { ...this.whereFilter }
      if (this.$route.query.module_name) {
        if (!this.cvCardTitle.includes(this.$route.query.module_name.toUpperCase())) {
          // avoids appending the module name in the card title multiple times when applying filters
          this.cvCardTitle = this.$route.query.module_name.toUpperCase() + " " + this.cvCardTitle
        }
        payload.module_name = this.$route.query.module_name
      }
      if (this.userData.user_role == "USERROLE11118") {
        // Remove date filter
        delete payload.dateRange
      }
      const affListResp = await Affiliates.AffiliateList(this, payload)
      if (affListResp.resp_status) {
        // assigning propper serial numbers
        let affListObj = affListResp.resp_data.data
        affListObj.forEach((e, index) => { e.id = (index + 1); e.action = null })

        this.gpath_affiliate_codeObjList = affListObj
        this.totalRows = affListResp.resp_data_count
      }
      else {
        this.toastMsg = affListResp.resp_msg
        this.toastVariant = "danger"
        this.showToast = true
      }
    },

    /**
    * get Affiliate Code Status Count
    */
    async getAffiliateCount () {
      const affCountResp = await Affiliates.AffiliateCodeAllocationCountGet(this)
      if (affCountResp.resp_status) {
        this.gpath_affiliate_count = affCountResp.resp_data
      }
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")

      document.body.appendChild(dummy)
      // Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    },
    /**
     * user Is Org Admin
     */
    userIsOrgAdmin () {
      if (this.userData.roles[this.orgData.org_id].udr_name && (this.userData.roles[this.orgData.org_id].udr_name.toLowerCase() === "admin" || this.userData.roles[this.orgData.org_id].udr_name.toLowerCase() === "owner")) {
        return true
      }
      return false
    },
    /**
     * get OrgSaas AffiliateList
     */
    async getOrgSaasAffiliateList () {
      try {
        const orgSaasObj = {
          user_id: this.userData.user_id,
          org_id: this.orgData.org_id
        }
        orgSaasObj.filter = {}

        if (this.$route.query.module_name) {
          if (!this.cvCardTitle.includes(this.$route.query.module_name.toUpperCase())) {
            // avoids appending the module name in the card title multiple times when applying filters
            this.cvCardTitle = this.$route.query.module_name.toUpperCase() + " " + this.cvCardTitle
          }
          orgSaasObj.module_name = this.$route.query.module_name
        }

        if (this.whereFilter.aff_code_status || this.whereFilter.aff_code_status == 0) {
          orgSaasObj.filter.aff_code_status = this.whereFilter.aff_code_status
        }

        if (this.whereFilter.counsellor_id) {
          orgSaasObj.filter.counsellor_id = this.whereFilter.counsellor_id
        }

        const orgSaasAffList = await OrgSaas.orgSaasAffiliateList(this, orgSaasObj)
        if (orgSaasAffList.resp_status) {
          this.gpath_affiliate_codeObjList = orgSaasAffList.resp_data.data
          this.totalRows = this.gpath_affiliate_codeObjList.length
        }
        else {
          ApiResponse.responseMessageDisplay(this, orgSaasAffList)
        }
      }
      catch (err) {
        console.error("Exception in getOrgSaasAffiliateList and err: ", err)
      }
    },

    /**
     * getStateColorCode
     */
    getStateColorCode (affCodeStatus) {
      if (affCodeStatus == "ALLOCATED") {
        return `vertical-align: middle; color: #FFF; background: #3366CC !important;`
      }
      else if (affCodeStatus == "NOT ALLOCATED") {
        return `vertical-align: middle; color: #FFF; background: #FF9900 !important;`
      }
      else {
        return `vertical-align: middle; color: #FFF; background: #e5252c !important;`
      }
    },

    /**
     * getOrgDetails
     */
    async getOrgDetails () {
      try {
        const orgDomain = window.location.hostname
        const orgDetails = await Organisations.organisationView(this, this.orgId, orgDomain)
        if (!orgDetails.resp_status) {
          return
        }
        this.orgData = orgDetails.resp_data.data

        // set the org data in local storage for saving in user table
        this.$store.dispatch("SaasOrgData/setSaasOrgData", this.orgData)
      }
      catch (err) {
        console.error("Exception in getOrgDetails and err: ", err)
      }
    },
    /**
     * emitPaymentStatus
     */
    emitPaymentStatus () {
    },
    /**
     * purchaseAffCodes
     */
    purchaseAffCodes () {
      this.$router.push("/subscription_plans")
    },
    /**
     * Calling Function as per whereFilter
    */
    async getAffCodesDateWise () {
      switch (this.userData.user_role) {
        case "USERROLE11116":
          await this.getOrgSaasAffiliateList()
          break
        default:
          await this.getAffiliateList()
      }

      await this.setDateFilter()
    },
    /**
     * setFilters
     */
    async setFilters () {
      // setting counsellor name filters
      if (this.userData.user_role == "USERROLE11116") {
        this.orgSaasTeamList()
        return
      }

      const userListSearchResp = await User.userListSearch(this, { user_role: "USERROLE11118,USERROLE11111,USERROLE11116 " })
      if (userListSearchResp.resp_status) {
        this.counsellorObjList = userListSearchResp.resp_data.data
      }

      // setting module name filters
      const moduleListResp = await modules.moduleList(this)
      if (moduleListResp.resp_status) {
        this.moduleObjList = moduleListResp.resp_data.data
      }
    },

    /**
     * orgSaasTeamList
     */
    async orgSaasTeamList () {
      let moduleObjId = null

      try {
        moduleObjId = this.orgData.org_id

        let userListResp = await User.userInviteList(this, moduleObjId, this.whereFilter)
        if (userListResp && !userListResp.resp_status) {
          return false
        }
        else {
          this.counsellorObjList = userListResp.resp_data
        }
      }
      catch (err) {
        console.error("Exception occurred at userList() and Exception:", err.message)
      }
    },

    /**
     * set Set Session State
    */
    async setCounsellor () {
      switch (this.userData.user_role) {
        case "USERROLE11116":
          await this.getOrgSaasAffiliateList()
          break
        default:
          await this.getAffiliateList()
      }
    },
    /**
     * setModuleName
     */
    async setModuleName () {
      switch (this.userData.user_role) {
        case "USERROLE11116":
          await this.getOrgSaasAffiliateList()
          break
        default:
          await this.getAffiliateList()
      }
    },
    /**
     * setAffCodeStatus
     */
    async setAffCodeStatus () {
      switch (this.userData.user_role) {
        case "USERROLE11116":
          await this.getOrgSaasAffiliateList()
          break
        default:
          await this.getAffiliateList()
      }
    },
    /**
   * Setting Date Range in the LS
   */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * gpath_sendMail
     */
    async gpath_sendMail (type, gpathAffObj) {
      try {
        this.sendMailObj = {
          user_id: gpathAffObj.user_id,
          user_name: gpathAffObj.user_name,
          user_email: gpathAffObj.user_email,
          cc_email: gpathAffObj.parent_email,
          subject: { GPATH_AFF_CODE: this.SendMailModules.GPATH_AFF_CODE }
        }
        this.showModelSendMail = true
      }
      catch (err) {
        console.error("Exception occurred at gpath_sessionEdit() and Exception:", err.message)
      }
    },
    /**
     * goBack
     * routes the user to the previous page
     */
    async goBack () {
      this.$router.go(-1)
    },
    /**
     * emitCloseGPathSendMailModal
     */
    async emitCloseGPathSendMailModal () {
      this.showModelSendMail = false
    },
    /**
     * setTagStyles
     */
    setTagStyles (index) {
      let styles = "cursor:pointer;font-size:1rem;"
      if (index == "allocated_count") {
        styles += "color:#3366cc !important;" // blue for allocated count
      }
      else if (index == "unallocated_count") {
        styles += "color:#FF9900;" // Orange for unallocated count
      }
      else if (index == "used_code") {
        styles += "color:#E5252c;" // Red for used code
      }
      return styles
    }
  }
}
</script>

<style>
.tags{
  border: 1px solid;
  font-size: 14px;
}
</style>
